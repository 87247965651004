import React, { useRef, useEffect, useState } from 'react'

import {
  Container,
  Logo,
  MenuItem,
  Seperator,
  Underline
} from './public-menu.styles'

import {
  icons
} from '../../../constants'

import {
  useRouteMatch,
  useLocation,
  Link
} from 'react-router-dom'

import { useTopNavTheme } from './top-nav-theme-context'
import { useFeaturedArtworkTheme } from 'components/zine/featured-artwork.context'

const menuItems = {
  "/here/manifesto": "manifesto",
  "/here/how-it-works": "how-it-works",
  "/signup": "signup",
  "/login": "signin"
}

const useScroll = () => {
  const hasScrolled = useRef(null)
  const [scrollStart, setScrollStart] = useState()
  const [scrollEnd, setScrollEnd] = useState()
  const [scrollChange, setScrollChange] = useState('start') // start, down, up
  const scrollLimit = 20
  
  const handleScroll = () => {
    setScrollStart(window.scrollY)    
  }

  const handleScrollEnd = () => {
    setScrollEnd(window.scrollY)
  }
  
  useEffect(() => {
    hasScrolled.current = false
    document.addEventListener('scroll',handleScroll)
    document.addEventListener('scroll',handleScrollEnd)
    
    setScrollChange('start')

    return () => {
      document.removeEventListener('scroll',handleScroll)
      document.removeEventListener('scroll', handleScrollEnd)
    }
  }, [])

  useEffect(() => {
    if (scrollEnd > scrollStart && scrollEnd > scrollLimit) {
      if (scrollChange !== 'down') {
        hasScrolled.current = true
        setScrollChange('down')
      }
    } else
    if (scrollEnd < scrollStart && scrollEnd > scrollLimit) {
      if (scrollChange !== 'up')
        setScrollChange('up')
    } else
    if (scrollEnd <= scrollLimit) {
      if (scrollChange !== 'start')
        setScrollChange('start')
    }
  }, [scrollStart, scrollEnd])

  return {
    scrolled: hasScrolled.current,
    scroll: scrollEnd,
    scrollChange
  }
}

export default function PublicMenu({
  isLoggedIn,
  theme
}) {
  const { publicMenuFlipped } = useTopNavTheme()
  const { opened } = useFeaturedArtworkTheme()
  const { pathname } = useLocation()
  const underlineRef = useRef()
  const { scrolled, scrollChange } = useScroll()

  const resetUnderline = () => {
    if (underlineRef.current) {
      const menuItem = menuItems[pathname]
      if (menuItem) {
        const elems = document.getElementsByClassName('menu-item-' + menuItem)
        // adjust position of underline
        if (elems.length > 0) {
          underlineRef.current.style.left = elems[0].offsetLeft + 'px'
          underlineRef.current.style.width = elems[0].clientWidth + 'px'
        }
      } else {
        underlineRef.current.style.left = '0px'
        underlineRef.current.style.width = '0px'
      }
    }
  }

  const handleMouseOver = (evt) => {
    if (underlineRef.current) {
      underlineRef.current.style.left = evt.target.offsetLeft + 'px'
      underlineRef.current.style.width = evt.target.clientWidth + 'px'
    }
  }

  const handleMouseOut = (evt) => {
    resetUnderline()
  }

  useEffect(() => {
    setTimeout(() => {
      resetUnderline()
    }, 500)
  }, [])

  useEffect(() => {
    setTimeout(() => {
      resetUnderline()
    }, 500)
  }, [pathname])
  
  useEffect(() => {
    setTimeout(() => {
      resetUnderline()
    }, 500)
  }, [])

  let menuTheme = 'light'
  let background = 'pink'
  let showLogo = false
  let disableFlip = true
  let flip = false

  // showLogo = true
  if (!isLoggedIn) {
    showLogo = true
    if (pathname.indexOf('/articles/') === 0 
      || pathname.indexOf('/itch-articles/') === 0
      || pathname.indexOf('/archives/itch-articles') === 0) {
      background = 'dark'
    } else
    if (pathname === '/' || pathname === '/zine' 
      || pathname.indexOf('/zine/') === 0
    ) {
      background = 'transparent'
      menuTheme = theme
      if (!opened) {
        if (scrollChange !== 'start') {
          flip = true
          disableFlip = false
          showLogo = true
          menuTheme = 'light'
        } else {
          flip = publicMenuFlipped
          // flip = false
          disableFlip = !scrolled
          showLogo = false
        }
      } else {
        flip = true
        disableFlip = false
        showLogo = true
        menuTheme = 'light'
      }
    } else
    if (['/here/manifesto', '/here/how-it-works', '/signup','/login'].includes(pathname) || pathname.indexOf('/signup/') === 0) {
      background = 'transparent'
      if (pathname === '/here/how-it-works') {
        menuTheme = 'dark2'
      }
    }
  } else {
    // logged in
    background = 'transparent'
    if (pathname === '/here/how-it-works') {
      menuTheme = 'dark2'
    }
  }

  const handleOnLogoClick = () => {
    if (pathname === '/' || pathname === '/zine') {
      window.scrollTo(0,0)
    }
  }

  return (
    <Container 
      id="public-menu"
      disableflip={disableFlip}
      flip={flip} 
      menutheme={menuTheme}
      background={background}
      showLogo={showLogo} 
      isLoggedIn={isLoggedIn}>
      {!isLoggedIn ? <>
      <Logo className="logo" showLogo={showLogo} menuTheme={menuTheme} background={background} >
        <Link to={`/`} onClick={handleOnLogoClick}>
          <img src={icons[menuTheme === 'dark' ?'logo-desktop-nav-2b2a29.svg':'logo-desktop-nav-efefef.svg']} alt="" />
          <img src={icons['logo-desktop-nav-ec0f69.svg']} alt="" />
        </Link>
      </Logo>
      <Seperator className="v-sep" disableflip={disableFlip} menutheme={menuTheme} hide={!showLogo} />
      </> : null}
      <MenuItem onMouseOver={handleMouseOver} 
        disableflip={disableFlip}
        menutheme={menuTheme}
        background={background}
        onMouseOut={handleMouseOut}
        className="menu-item menu-item-manifesto" to={`/here/manifesto`}>
        manifesto
      </MenuItem>
      <Seperator className="v-sep" disableflip={disableFlip} menutheme={menuTheme}/>
      {pathname === '/here/manifesto' || pathname === '/here/how-it-works' ? <>
        <MenuItem onMouseOver={handleMouseOver}
          disableflip={disableFlip} 
          flip={flip} 
          menutheme={menuTheme}
          background={background}
          onMouseOut={handleMouseOut}
          className="menu-item menu-item-how-it-works" to={'/here/how-it-works'}>
        how it works
        </MenuItem>
        {!isLoggedIn ? <Seperator className="v-sep" disableflip={disableFlip} menutheme={menuTheme} /> : null}
      </> : null}
      {!isLoggedIn ? 
      <>
      <MenuItem onMouseOver={handleMouseOver}
        disableflip={disableFlip} 
        flip={flip} 
        menutheme={menuTheme}
        background={background}
        onMouseOut={handleMouseOut}
        className="menu-item menu-item-signup" to={'/signup'}>
        sign up
      </MenuItem>
      <Seperator className="v-sep" disableflip={disableFlip} menutheme={menuTheme}/>
      <MenuItem onMouseOver={handleMouseOver}
        disableflip={disableFlip} 
        flip={flip} 
        menutheme={menuTheme}
        background={background}
        onMouseOut={handleMouseOut}
        className="menu-item menu-item-login" to={`/login`}>
        log in
      </MenuItem>
      </> : null}
      <Underline menutheme={menuTheme} ref={underlineRef} />
    </Container>
  )
}